import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import qs from "query-string";
import Hero from "../../components/Hero/Hero";
import SearchBar from "../../components/SearchBar/SearchBar";
import GMBScoring from "../../components/GMBScoring/GMBScoring";
// import CompetitorsScoring from '../../components/CompetitorsScoring/CompetitorsScoring';
import Recaptcha from "react-recaptcha";
import { message, Icon, notification, Alert } from "antd";
import { parseBusinessFromDBToValidFormat } from "../../utils/utils";
// import { LoaderDots } from "@thumbtack/thumbprint-react";
import { Helmet } from "react-helmet";
import "./audit.css";
import { getPopulateCompetitorsPDF } from "../../utils/competitors";
import InfoStats from "./InfoStats";
import Logos from "./Logos";
import ModalMoreInfo from "./ModalMoreInfo";
import CookieConsent from "react-cookie-consent";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:4000/tech";

const Home = () => {
  const [GMBInfo, setGMBInfo] = useState();
  const [displayGMBSection, setDisplayGMBSection] = useState(false);
  const [submitButton, setSubmitButton] = useState("Analyse");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState();
  const [isVerified, setIsVerified] = useState(false);
  const [hasFullAccess, setFullAccess] = useState(false);
  const [copied, setCopied] = useState(false);
  const [sessionID, setSessionID] = useState();
  const [businessAddress, setBusinessAddress] = useState("");
  const [modalBusinessInfo, setModalBusinessInfo] = useState(false);
  const [isSA, setIsSA] = useState(false);
  let location = useLocation();

  useEffect(() => {
    const isSuperAdmin = () => {
      if (location.search.includes("access=localranker")) {
        setIsSA(true);
      }
    };

    isSuperAdmin();
  }, []);

  const handleOpenModalBusinessInfo = (businessAddress) => {
    setModalBusinessInfo(true);
  };

  const submitBusiness = (businessAddress, businessInfo) => {
    try {
      console.log(businessAddress);
      // Where we're fetching data from
      setModalBusinessInfo(false);
      if (isVerified === true) {
        setSubmitButton(<Icon type="loading" />);
        setIsLoading(true);
        setTimeout(() => {
          setLoadingMessage("🕵️‍♂️ Vérification de votre fiche");
        }, 100);
        setTimeout(() => {
          setLoadingMessage("🔎 Analyse de votre e-reputation");
        }, 5000);
        setTimeout(() => {
          setLoadingMessage("📊 Analyse de la concurrence");
        }, 10000);
        setTimeout(() => {
          setLoadingMessage("🏄‍♂️ Surfing sur le local pack");
        }, 16000);
        setTimeout(() => {
          setLoadingMessage("🙌 Résultats dans quelques secondes");
        }, 25000);

        fetch(
          `${API_URL}/gmb-audit?businessAddress=${businessAddress}${
            businessInfo
              ? `&businessInfo=${JSON.stringify(businessInfo)}`
              : isSA
              ? "&sa=true"
              : ""
          }`,
          {
            method: "POST",
          }
        )
          // We get the API response and receive data in JSON format...
          .then((response) => response.json())
          // ...then we update the users state
          .then(async (data) => {
            if (data === "Local Business Not Found") {
              message.error("Local Business Not Found");
              setIsLoading(false);
              setSubmitButton("Analyse");
            } else if (data === "Error") {
              message.error("Something went wrong");
              setSubmitButton("Analyse");
              setIsLoading(false);
            } else {
              if (!data.err) {
                const resp = await parseBusinessFromDBToValidFormat(data);
                setIsLoading(false);
                setGMBInfo(resp);
                if (isSA) setFullAccess(true);
                if (!isSA) setFullAccess(false);
                setDisplayGMBSection(true);
                setSubmitButton("Analyse");
                setIsVerified(false);
                return resp;
              } else {
                message.error("Veuillez insérer un email valide.");
                setSubmitButton("Analyse");
                setIsLoading(false);
              }
            }
          })
          .catch((e) => {
            message.warning(
              "Vos 2 essais sont expirés, ré-essayez plus tard ou utilisez LocalRanker"
            );
            setSubmitButton("Analyse");
            setIsLoading(false);
          });
      } else {
        message.warning("Veuillez résoudre le captcha pour accéder à l'audit");
      }
    } catch (e) {
      message.error("Erreur lors de l'analyse");
      setSubmitButton("Analyse");
      setIsLoading(false);
    }
  };

  const recaptchaLoaded = () => {};

  const verifyCallback = (response) => {
    if (response) {
      setIsVerified(true);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Audit fiche Google My Business | LocalRanker</title>
        <meta
          name="description"
          content="Auditez gratuitement votre fiche GMB et commencez les optimisations pour dépasser vos concurrents !"
        />
      </Helmet>
      <Hero
        title="Outil d'audit fiche Google My Business"
        subtitle="Améliorez votre référencement et e-reputation en quelques clics"
        emoji="📍"
      />
      {/* <div style={{ width: "50%", marginLeft: "auto", marginRight: "auto" }}>
        <Alert
          message="Outil non disponible pour le moment. Nous sommes en train d'apporter des modifications majeures à l'audit. L'outil sera disponible très prochainement avec des nouveautés 🔥."
          type="warning"
        />
      </div> */}
      <SearchBar
        handleOpenModalBusinessInfo={handleOpenModalBusinessInfo}
        submitButton={submitButton}
        setBusinessAddress={setBusinessAddress}
        businessAddress={businessAddress}
        submitBusiness={submitBusiness}
        isSa={isSA}
      />
      {isLoading && (
        <div className="loading-message">
          <span style={{ display: "inline-block" }}>
            {loadingMessage}
            <Icon
              type="loading"
              style={{ fontSize: "25px", marginLeft: "20px" }}
            />
          </span>
        </div>
      )}
      {isLoading || displayGMBSection ? (
        ""
      ) : (
        <div className="audit">
          <Recaptcha
            sitekey="6LdVyOsUAAAAALCyJsUuFb0qXONAbkFzMrSZww2_"
            render="explicit"
            onloadCallback={recaptchaLoaded}
            verifyCallback={verifyCallback}
          />
        </div>
      )}
      {!displayGMBSection ? <InfoStats /> : ""}
      <GMBScoring
        hasFullAccess={hasFullAccess}
        displayGMBSection={displayGMBSection}
        googleInfo={GMBInfo}
        copied={copied}
        setCopied={setCopied}
        sessionID={sessionID}
      />
      <br />
      <br />
      <ModalMoreInfo
        modalBusinessInfo={modalBusinessInfo}
        setModalBusinessInfo={setModalBusinessInfo}
        submitBusiness={submitBusiness}
        businessAddress={businessAddress}
      />
      <CookieConsent
        location="bottom"
        buttonText="Parlez à un expert"
        style={{ background: "#FFF", color: "#3C3C3C", alignItems: "center" }}
        buttonStyle={{
          backgroundColor: "#FF4E4E",
          fontSize: "16px",
          color: "#FFF",
          borderRadius: "4px",
        }}
        onAccept={() => {
          window.open("https://localranker.fr/demander-demo/");
        }}
        hideOnAccept={false}
      >
        <div style={{ float: "right", fontSize: "16px" }}>
          <span role="img" aria-label="emoji">
            🏢
          </span>{" "}
          Vous avez plusieurs fiches GMB ? Echangez directement avec un expert
          pour votre audit.
        </div>
      </CookieConsent>
    </div>
  );
};

export default Home;
